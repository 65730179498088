<template>
  <div class="page-wrapper">
    <h1>Gestion des questions</h1>

    <h2>Liste des questions</h2>
    <form @submit.prevent>
      <input v-model="search" type="text" placeholder="Rechercher une question" @keyup="filterQuestionsList" />
    </form>

    <v-table
      v-if="filteredQuestionsList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="filteredQuestionsList"
    >
      <thead slot="head">
        <v-th sort-key="name">Question</v-th>
        <v-th sort-key="category">Catégorie</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.category }}</td>
          <td>
            <button @click.prevent="editQuestion($event, row)">🖊</button>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteQuestion($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <v-table
      v-else-if="questionsList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="questionsList"
    >
      <thead slot="head">
        <v-th sort-key="name">Question</v-th>
        <v-th sort-key="category">Catégorie</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.category }}</td>
          <td>
            <button @click.prevent="editQuestion($event, row)">🖊</button>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteQuestion($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <h2>Ajout/Modification</h2>
    <!-- Auth UI -->
    <form @submit="saveQuestion($event)">
      <div>
        <label for="name">Question</label>
        <input id="name" v-model="question.name" type="text" required />
      </div>

      <div>
        <label for="category">Catégorie</label>
        <select id="category" v-model="question.category" name="category" required>
          <option value="Général" selected>Général</option>
          <option value="Statistiques">Statistiques</option>
          <option value="Programmes">Programmes</option>
        </select>
      </div>

      <div>
        <label for="answer">Réponse</label>
        <textarea id="answer" v-model="question.answer" required></textarea>
      </div>

      <div>
        <button type="submit" class="bt" :class="{ 'is-loading': isLoading }">
          <div class="bt-text">
            <p v-if="mode === 'create'">Ajouter</p>
            <p v-else>Modifer</p>
          </div>
          <p class="loader">
            &nbsp;
            <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
              <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
                <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </svg>
            &nbsp;
          </p>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import QuestionsDB from '@/firebase/questions-db'

export default {
  data: () => ({
    isLoading: false,
    question: {},
    questionsList: null,
    filteredQuestionsList: null,
    mode: 'create',
    search: ''
  }),
  computed: {
    ...mapState('authentication', ['user'])
  },
  mounted() {
    this.getQuestionsList()
  },
  methods: {
    async saveQuestion(e) {
      e.preventDefault()

      this.isLoading = true

      const question = { ...this.question }
      const questionsDB = new QuestionsDB()
      console.log({ question, mode: this.mode })

      if (this.mode === 'create') {
        await questionsDB.create(question)
      } else {
        await questionsDB.update(question)
      }

      this.getQuestionsList()

      this.question = {}
      this.isLoading = false
      this.mode = 'create'
    },
    async editQuestion(e, question) {
      this.isLoading = true
      const questionsDB = new QuestionsDB()
      this.question = await questionsDB.read(question.id)
      this.mode = 'update'
      this.isLoading = false
    },
    async deleteQuestion(e, questionId) {
      this.isLoading = true

      const userChoice = await this.$swal({
        title: 'Supprimer',
        text: `Confirmer la suppression.`,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
      })

      if (!userChoice.isConfirmed) {
        this.isLoading = false
        return
      }

      const questionsDB = new QuestionsDB()
      await questionsDB.delete(questionId)
      this.getQuestionsList()
      this.isLoading = false
    },
    async getQuestionsList() {
      this.isLoading = true
      const questionsDB = new QuestionsDB()
      this.questionsList = await questionsDB.readAll()
      this.isLoading = false
    },
    filterQuestionsList() {
      if (!this.questionsList) {
        return
      }

      if (this.search && this.search.length >= 3) {
        this.filteredQuestionsList = this.questionsList.filter(i => i.name.includes(this.search))
      } else {
        this.filteredQuestionsList = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
